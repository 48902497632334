<template>
  <div>
    <v-container>
      <v-row class="mb-4">
        <h2>Introductory Information</h2>
      </v-row>

      <v-simple-table>
        <thead>
          <tr v-for="field in fields" :key="field.inputKey">
            <td style="width: 220px">
              {{ field.name }}
            </td>
            <td>
              <v-text-field v-model="inputs[field.inputKey]" />
            </td>
          </tr>
        </thead>
      </v-simple-table>
    </v-container>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default {
  name: 'McMaster1A03Introduction',
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        title: null,
        author: null,
        studentID: null,
        date: null,
        partner: null,
      },
      fields: [
        {name: 'Experiment Title:', inputKey: 'title'},
        {name: 'Name:', inputKey: 'author'},
        {name: 'Student Number:', inputKey: 'studentID'},
        {name: 'Date Experiment Performed: ', inputKey: 'date'},
        {name: "Partner's Name (if applicable): ", inputKey: 'partner'},
      ],
    };
  },
};
</script>

<style scoped></style>
